export const NotFound = () => {
  return (
    <div>
      <h1>Страница не найдена</h1>
      <div>
        Эта страница, которую вы ищете, могла быть удалена, ее название было
        изменено или она временно недоступна.
      </div>
    </div>
  );
};
