import { Accordion } from "../../component/Accordion/Accordion";
import "./Faq.css";
const helpful = [
  {
    id: 0,
    title: "Заказ",
    description:
      "Для заказа необходимо заполнить форму на сайте или связаться со мной любым удобным способом, указанным в разделе «Контакты». Информация, необходимая для совершения заказа: вид изделия, цвет, мерки, город доставки. Если какой-то из пунктов вызывает затруднения, я всегда рада помочь с выбором вида изделия, цвета, снять мерки, выбрать подходящий способ доставки. ",
  },

  {
    id: 1,
    title: "Какую пряжу выбрать",
    description:
      "Выбор пряжи зависит от собственных предпочтений. Если хотите пушистое изделие, ваш выбор – шерсть ангоры, изделия из ангоры в зависимости от толщины подойдут не только на осень/весну, но будут согревать и зимой. Объёмная пряжа подходит для шапок и варежек на зиму, а для дополнительной защиты от морозов и ветра можно подшить подклад из флиса. Шерсть мериноса отличается своей гиппоаллергеностью и мягкостью, она отлично сохраняет тепло, но в то же время в изделиях из шерсти мериноса нежарко. Изделия из такой пряжи подходят на осень/весну и европейскую зиму.",
  },
  {
    id: 2,
    title: "Срок изготовления",
    description:
      "Срок изготовления зависит от заполненности очереди на момент заказа. ",
  },
  {
    id: 3,
    title: "Оплата",
    description:
      "После уточнения всех деталей заказа высылаются реквизиты для оплаты. Заказ считается принятым после внесения предоплаты в размере 30%, оплата остальной суммы производится по готовности изделия. ",
  },
  {
    id: 4,
    title: "Доставка",
    description:
      "Доставка осуществляется «Почтой России» или логистической компанией «CDEK», оплачивается покупателем в соответствии с установленными тарифами. ",
  },
  {
    id: 5,
    title: "Стирка",
    description:
      "Перед отправкой изделие проходит влажно-тепловую обработку, поэтому после получения заказа стирать изделие не нужно. Рекомендуется постирать изделие в конце сезона и во избежание деформации убрать в пыльник (идёт в комплекте) на хранение. Рекомендуется ручная стирка при 30 градусах с жидким моющим средством, изделие не тереть, во время отжима не выкручивать. Сушить изделие необходимо на ровной поверхности, предварительно расправив, чтобы узор во время сушки не деформировался.",
  },
];

export const Faq = () => {
  return (
    <div className="title-section">
      <div className="helpful-container">
        <h1 className="helpful-h1">
          Здесь Вы можете найти ответы на часто задаваемые вопросы
        </h1>

        <div className="helpful-info-container">
          {helpful.map((info) => (
            <Accordion {...info} key={info.id} />
          ))}
        </div>
        <h3 className="helpful-h3">
          Если остались вопросы, свяжитесь со мной любым удобным способом, и я
          буду рада Вам помочь.
        </h3>
      </div>
    </div>
  );
};
